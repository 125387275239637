import React from "react";
import Layout from "../components/layout/layout";
import { getUserQuestionnaire } from "../components/questionnaire-wizard/questionnaire-data-service";
import SEO from "../components/seo";
import ServicePaymentForm from "../components/service-payment-form/service-payment-form";
import withLocation from "../components/withLocation";
import { qDataToArray } from "../utils/helpers";
import { sendEmailPaymentCompleted } from "../utils/mailService";

import "./pago-servicio.scss";

const handlePaymentCompleted = (responsePaypal, data) => {
    // TODO recoger datos del ServicePaymentForm
    const formData = { ...responsePaypal.formData, serviceName: data.title, servicePrice: data.amount };

    // TODO recoger datos del localStorage questionnaireData
    const questionnaireData = getUserQuestionnaire();

    // TODO recoger datos del pago de paypal
    const paymentData = responsePaypal.paymentData;
    // console.log(
    //     "JES onPaymentCompleted",
    //     formData,
    //     questionnaireData,
    //     paymentData
    // );

    sendEmailPaymentCompleted({
        formData,
        questions: qDataToArray(questionnaireData),
        paymentData,
    });
};

const getDataByType = {
    type1: {
        title: "Completa el pago del dictamen",
        amount: 140,
    },
    type2: {
        title: "Completa el pago del dictamen",
        amount: 110,
    },
};

function PagoServicio({ search }) {
    const { type } = search;
    const data = getDataByType[type];

    if (!data) {
        return (
            <Layout pageId="service-generic-payment">
                <div className="sgp-container">
                    <br></br>
                    <h3>Error en la carga de la página</h3>
                    <br></br>
                    <p>
                        Si el error persiste póngase en contacto con el
                        administrador en {" "}
                        <strong>admin@negligenciasdeabogados.net</strong>
                    <br></br>
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </Layout>
        );
    }

    return (
        <React.Fragment>
            <SEO  title="Pago Servicio"/>
            <Layout pageId="service-generic-payment">
                <div className="sgp-container">
                    <h1>{data.title}</h1>

                    <ServicePaymentForm
                        title="Pago Dictamen"
                        amount={data.amount}
                        onPaymentCompleted={(responsePaypal) => {
                            handlePaymentCompleted(responsePaypal, data);
                        }}
                    />
                </div>
            </Layout>
        </React.Fragment>
    );
}

export default withLocation(PagoServicio);
